<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import RegionsData from "./regions-data";
import AddRegion from "./add-region";

import {
  layoutComputed,
  authComputed,
  regionsMethods,
  regionsComputed,
} from "@/state/helpers";
/**
 * Regions component
 */
export default {
  page: {
    title: "Regions",
    meta: [{ name: "Regions" }],
  },
  components: {
    Layout,
    PageHeader,
    RegionsData,
    AddRegion,
  },
  mounted() {
    this.retrieveRegions();
  },
  data() {
    return {
      title: "titles.regions.text",

      busy: false,

      regionsData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...regionsComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.regions.text"),
          active: true,
        },
      ]
    },

    userRole() {
      return this.loggedIn.user.role;
    },
  },
  methods: {
    ...regionsMethods,

    retrieveRegions() {
      if (this.getRegions.results) {
        this.regionsData = this.getRegions.results;
      } else {
        this.busy = true;
        this.getRegionsBy({ limit: 100 }).then(() => {
          this.busy = false;
        });
      }
    },

    openAdd() {
      this.showModalAdd();
    },

    showModalAdd() {
      this.$bvModal.show("add-region-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-region-modal");
    },

    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },

    add(region) {
      this.addRegion(region)
        .then((region) => {
          this.makeToast(
            "Add region",
            region.name + " has been added",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Add region", error, "danger");
        });
    },

    clear() {
      this.regionsFilter = {
        serial: null,
        name: null,
        type: null,
        region: null,
        province: null,
        state: null,
      };
      this.regions = [null];
      this.provinces = [null];
    },

    cancel() {
      return;
    },
    cleanObject(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  watch: {
    getRegions(newVal) {
      this.regionsData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addRegion.text")}}
        </b-button>
        <AddRegion @addRegion="add" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <RegionsData v-bind:regions="regionsData" :busy="busy" />
      </div>
    </div>
  </Layout>
</template>